.totalMain {
    width: 100vw;
    border-radius: 1px red solid;
    justify-content: center;
    align-items: center;
}

.hola {
    height: 50%;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputSearch {
    border-radius: 0.5rem;
    margin-right: 3rem;
    font-size: 16px;
    padding: 5px;
    background-color: transparent;
    border: solid black 1px;
}


.DataIcon {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.DataIcon:hover {
    transform: scale(1.2);
}


.App {
    background-color: #f6f6f6;
    width: 90vw;
    border-radius: 1rem;
    height: 86vh;
    padding: 1rem;
}

.rdt_TableHeader {
    border-radius: 1rem;
    background-color: #f6f6f6 !important;
}

/* th */
.rdt_TableHeadRow {
    background-color: #f6f6f6 !important;
}

.rdt_TableRow {
    background-color: #f6f6f6 !important;
}

.rdt_Pagination {
    background-color: #f6f6f6 !important;
}

.StatusMenu {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    transition: transform 0.3s;
}

.StatusMenu:hover {
    transform: scale(1.07);
}

.StatusMenuOption {
    transition: transform 0.3s;
    cursor: pointer;
}

.StatusMenuOption:hover {
    transform: scale(1.3);
}


.modal::-webkit-scrollbar-thumb {
    background-color: #641616;
    /* Color de la barra del scrollbar */
    border-radius: 6px;
    /* Radio de borde de la barra del scrollbar */
}

.switch {
    height: 6vh;
    width: 10vw;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-radius: 0.5rem;
}

.switchoff {
    border: red solid 2px;
}

.switchon {
    border: green solid 2px;
}

.switchactivo {
    background-color: green;
}

.switchinactivo {
    background-color: red;
}

.switchoffC {
    border: blueviolet solid 2px;
}

.switchonC {
    border: rgb(79, 79, 190) solid 2px;
}

.switchactivoC {
    background-color: rgb(79, 79, 190);
}

.switchinactivoC {
    background-color: blueviolet;
}

.onoff {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusButton {
    background-color: #A0A2A2;
    border-radius: 50%;
    width: 5%;
    height: 60%;
    margin-left: 0.35rem;
}

.statusMain {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.statusTotalMain {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-size: 13px;
}

.statusTotalMain>div {
    width: 50%;
    text-align: center;

}

#tablalog>.ktEZNl {
    overflow-y: scroll;
    overflow-x: auto;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    padding: 1rem;
}

#tablalog {
    height: 70vh !important;
    padding-bottom: 1rem;
}

#tablalog > .rdt_TableHeader {
    border-radius: 1rem;
    background-color: white !important;
} 

/* th */
#tablalog > .ktEZNl > .dvkOGB > .edNzxa > .jDCdsp > .rdt_TableHeadRow {
    background-color: white !important;
}


#tablalog > .ktEZNl > .dvkOGB > .edNzxa > .bLUMWQ > .rdt_TableRow {
    background-color: white !important;
}

#tablalog > .ktEZNl > .dvkOGB > .edNzxa > .bLUMWQ > .rdt_TableRow > .rdt_TableCell:first-child {
    margin: 0;
}

/* .App > .ktEZNl{
    overflow-y: auto;
    height: 80%;
    border: solid blue 1px;
} */