.sidebar {
    padding: 0.3rem;
    padding-top: 1rem;
    height: 100vh;
    background-color: #302f2f;
    width: 7vw;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
}

.top {
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    color: #c1c1c1;
}

.bottom {
    padding: 10%;
    width: 90%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    color: #c1c1c1;

}

.profilePictureContainer {
    width: 100%;
    display: none;
    justify-content: center;
}

.profilePicture {
    width: 60%;
    height: 3rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: 50%;
    box-shadow: 0 0 10px #bdbcbc;
}

.logout {
    margin-bottom: 1rem;
}

.config {
    margin-bottom: 2rem;
}

.home {
    margin-top: 2rem;
}

.user {
    margin-top: 2rem;
}

.folder {
    margin-top: 2rem;
}

.sliders {
    margin-top: 2rem;
}

.icon {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    color: #c1c1c1;
    transition: transform 0.3s ease;
}


.icon:hover {
    color: #88acbe;
    transform: scale(1.2);
}

.icon:hover::before {
    content: attr(data-label);
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-5%);
    padding: 5px;
    background-color: #413f3f;
    color: #f2f2f2;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 10;
}



.icon:hover::before,
.icon:hover::after {
    opacity: 1;
    visibility: visible;
    z-index: 10;
}

.atributosContainer {
    transition: gap 0.6s;
    margin-top: 1rem;
    padding: 10%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #3a3a3a;
    width: 95%;
    border-radius: 1rem;
}