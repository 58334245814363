.UserNav{
    padding: 0.5rem;
    padding-right: 1.5rem;
    width: 100%;
    height: 8vh;
    background-color: #f7f7f7;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: end;
    color: #333333;
    font-weight: 500;
    font-size: 18px;
    position: absolute;
    z-index: 0;
}

.ancho-personalizado {
    width: 75vw; /* Puedes ajustar el valor del ancho según tus necesidades */
  }

.inputpass{
    display: flex;
    flex-direction: row;
    height: 6vh;
}

.inputpass > input:first-child{
    width: 90%;
}

.showpass{
    height: 100%;
    width: 10%;
    display: grid;
    place-items: center;
}

.showpass > svg:first-child{
    height: 100%;
    width: 50%;
    color: #333333;
}

.error-text{
    color: red;
}