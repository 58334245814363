.ContainerCharts{
}

.ContainerLogs{
    margin-top: 1%;
}

.DashboardContainer{
    
    width: 100%;
    height: 49%;
    border-radius: 1rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    padding: 0rem;
}

.DashboardContainer:hover{
    transform: scale(1.00);
}

.DashboardTitle{
    font-weight: bold;
    font-size: 23px;
    height: 15%;
    background-color: #302f2f;
    color: #dfdfdf;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    padding-left: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding-inline: 1px;
}

.DashboardTitle > div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ChartBox{
    height: 85%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding-inline: 1px;
}

.ChartBox > div:first-child{
    border-bottom-left-radius: 1rem;
}

.ChartBox > div:last-child{
    border-bottom-right-radius: 1rem;
}


.ChartContainer{
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
}

.ChartContainer:hover{
    transform: scale(1.015);
    cursor: pointer;
}

.modalChart .modal-content {
    width: 60vw;
    margin: 0;
    height: 90vh;
    margin-top: 5vh;
}

#modalChartAlumno {
    margin: 0;
    top: 50%;
    left: 40%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.modalChart .modal-body {
    padding: 10px;
    padding-top: 0;
    padding-bottom: 1rem !important;
}


.modalLog .modal-content {
    width: 80vw;
    margin: 0;
    height: 90vh;
    margin-top: 5vh;
}

#modalLog {
    margin: 0;
    top: 48%;
    left: 29%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.tablaLogMain{
    display: flex;
    flex-direction: row;
}

.tablaLogMain > div:first-child{
    width: 20%;
}

.tablaLogMain > div:nth-child(2){
    width: 25%;
}

.tablaLogMain > div:last-child{
    width: 50%;
}

#tablalog > .tablaLogMain:nth-child(even){
    background-color: #555555;
    color: #dfdfdf;
}

#tablalog{
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}